import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { 
  sanitizeHtmlContent, 
  //truncateText 
} from '../components/utils/utils'

import Seo from '../components/seo'
import GatsbyGoogleTag from "../components/gatsby-google-tag"

import TritonDefaultLayout from '../layout/default-layout'
import SectionHero from '../components/sections/SectionHero/SectionHero'
import SearchNewsItem from '../components/search/search-newsItem'

import { 
  Container,
  Segment,
  Item,
  Button,
  Header,
  Pagination,
  //Input,
} from 'semantic-ui-react';

dayjs.extend(utc)

export const Head = ({ location, }) => {
  //console.debug('pages - head', { location, params, data, pageContext });
  // handle page level seo content
  const seo = {
    pageTitle: 'All News',
    pageDescription: null,
  }
  return (
    <>
      <Seo pagePath={location.pathname} title={seo.pageTitle} description={seo.pageDescription} />
      <GatsbyGoogleTag />
    </>
  )
}

const PaginationIndexNewsItem = (props) => {
  //console.debug('PaginationIndexNewsItem - props', props);

  if (typeof window !== 'undefined') {
    document.body.id = 'pages-pagination-index-news-item';
  }

  const {
    edges,
  } = props.data.allNewsItem;

  const { humanPageNumber, numberOfPages, } = props.pageContext;

  const handlePaginationChange = (e, { activePage }) => {
    //console.debug('PaginationIndexNewsItem - handlePaginationChange', activePage);
    navigate('/about/newsroom/news/'+(activePage !== 1 ? activePage : ''));
  }

  const ItemImage = (props) => {
    const { displayText, thumbnailImage } = props;
    //console.debug('PaginationIndexNewsItem - thumbnailImage', thumbnailImage);
    const imageAltText = (displayText ? displayText : '') +' image';
    return (
      thumbnailImage.gatsbyImageData ?
        <GatsbyImage className={'ui image small'} image={getImage(thumbnailImage.gatsbyImageData)} alt={imageAltText} />
      : thumbnailImage.imageAbsoluteUrls && thumbnailImage.imageAbsoluteUrls.length !== 0 ?
        <Item.Image size={'small'} src={thumbnailImage.imageAbsoluteUrls[0]} alt={imageAltText} />
      : thumbnailImage.urls && thumbnailImage.urls.length !== 0 ?
        <Item.Image size={'small'} src={thumbnailImage.urls[0]} alt={imageAltText} />
      :
        null
    )
  }

  return (
    <>
      {/* <Seo title={'All News'} /> */}
      <TritonDefaultLayout subpage={'news-item-index'}>
        <SectionHero 
          commonPageSection={{
            "dOMID": "top",
            "textColor": "white",
            "textAlign": "left",
            "backgroundColor": "linear-gradient(225deg, rgb(254, 125, 76) 0%, rgb(224, 111, 126) 11%, rgb(148, 60, 215) 31%, rgb(75, 0, 161) 53%, rgb(93, 44, 208) 72%, rgb(18, 175, 222) 100%)",
          }}
          contentCollection={{
            contentItems: [
              {
                "contentItemId": "4hp70xdbw2ycwvfvhrdvhvmtcb",
                "contentType": "ContentHeadline",
                "displayText": "News Item Page Headline",
                "variationType": "h1",
                "textAlign": "center",
                "preheader": "Newsroom",
                "content": {
                  "html": "In The News"
                },
                "subheader": null,
                "maxWidth": "100%"
              }
            ]
          }} 
        />
        <SearchNewsItem />
        <Segment basic className={'news-item-list'}>
          <Container>
            <Item.Group relaxed={'very'} divided>
              {edges && edges.length !== 0 && edges.map((newsItem, i) => {
                const { displayText, publicationDate, publishedUtc, authorInfo, authorName, path, thumbnailImage, } = newsItem.node;
                const dateRender = publicationDate ? dayjs.utc(publicationDate).format('MMMM DD, YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM DD, YYYY') : null;
                const datePath = publicationDate ? dayjs.utc(publicationDate).format('MMMM-DD-YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM-DD-YYYY') : null;
                return (
                  <Item key={i}>
                    {thumbnailImage ?
                      <ItemImage displayText={displayText} thumbnailImage={thumbnailImage} />
                    :
                      null
                    }
                    {/* <ItemImage displayText={displayText} thumbnailImage={thumbnailImage} /> */}
                    <Item.Content>
                      <Item.Header as={Link} to={path ? ('/news-item/'+datePath+'/'+path) : null}>
                        <div className={'site-content-headline'}>
                          <Header as={'h3'} textAlign={'left'}>{displayText}</Header>
                        </div>
                      </Item.Header>
                      {authorInfo && authorInfo.html ?
                        <Item.Meta dangerouslySetInnerHTML={{__html: sanitizeHtmlContent(authorInfo.html)}}></Item.Meta>
                      : authorName ?
                        <Item.Meta dangerouslySetInnerHTML={{__html: sanitizeHtmlContent(authorName)}}></Item.Meta>
                      :
                        null
                      }
                      <Item.Extra>{dateRender}</Item.Extra>
                      {/* <Item.Description>
                        {displayText}
                      </Item.Description> */}
                    </Item.Content>
                    <Button className={'site-content-button outline'}
                      style={{ color: '#230859', borderColor: '#230859' }}
                      as={Link}
                      to={path ? ('/news-item/'+datePath+'/'+path) : null}
                      icon={'arrow right'}
                      labelPosition={'right'}
                      content={'Read More'}
                    />
                  </Item>
                )
              })}
            </Item.Group>
            <Segment basic textAlign={'center'} className={'pagination-wrapper'}>
              <Pagination size={'large'} aria-label={'Pagination Navigation'}
                activePage={humanPageNumber}
                totalPages={numberOfPages}
                onPageChange={handlePaginationChange}
                boundaryRange={1}
                siblingRange={0}
                ellipsisItem={true ? undefined : null}
                firstItem={null}
                lastItem={null}
                //firstItem={true ? undefined : null}
                //lastItem={true ? undefined : null}
                prevItem={true ? undefined : null}
                nextItem={true ? undefined : null}
              />
            </Segment>
          </Container>
        </Segment>
      </TritonDefaultLayout>
    </>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allNewsItem(
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          contentItemId
          contentType
          displayText
          publishedUtc
          publicationDate
          path
          authorInfo {
            html
          }
          thumbnailImage {
            imageAbsoluteUrls
            gatsbyImageData
          }
          authorName
        }
      }
    }
  }
`;

export default PaginationIndexNewsItem
