import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { 
  sanitizeHtmlContent, 
  //truncateText 
} from '../utils/utils'

import { 
  Container,
  Segment,
  Item,
  Button,
  Header,
  Input,
  Icon,
  Divider,
} from 'semantic-ui-react';

dayjs.extend(utc)

const SearchNewsItem = (props) => {
  //console.debug('SearchNewsItem - props', props);

  const allNewsItemQuery = useStaticQuery(graphql`
    query AllNewsItemQuery {
      allNewsItem(limit: 50) {
        edges {
          node {
            contentItemId
            contentType
            displayText
            publishedUtc
            publicationDate
            path
            authorInfo {
              html
            }
            thumbnailImage {
              imageAbsoluteUrls
              gatsbyImageData
            }
            authorName
          }
        }
      }
    }
  `);
  const { edges } = allNewsItemQuery.allNewsItem
  //console.debug('SearchNewsItem - edges', edges);

  const ItemImage = (props) => {
    const { displayText, thumbnailImage } = props;
    //console.debug('PaginationIndexNewsItem - thumbnailImage', thumbnailImage);
    const imageAltText = (displayText ? displayText : '') +' image';
    return (
      thumbnailImage.gatsbyImageData ?
        <GatsbyImage className={'ui image small'} image={getImage(thumbnailImage.gatsbyImageData)} alt={imageAltText} />
      : thumbnailImage.imageAbsoluteUrls && thumbnailImage.imageAbsoluteUrls.length !== 0 ?
        <Item.Image size={'small'} src={thumbnailImage.imageAbsoluteUrls[0]} alt={imageAltText} />
      : thumbnailImage.urls && thumbnailImage.urls.length !== 0 ?
        <Item.Image size={'small'} src={thumbnailImage.urls[0]} alt={imageAltText} />
      :
        null
    )
  }

  const [filterState, setFilterState] = useState({
    query: '',
    results: [],
  });

  const handleFilterSelect = (e) => {
    //console.debug('SearchNewsItem - handleFilterSelect', {e});
    const value = e.target.value;
    if (value.length >= 3) {
      if (value !== filterState.query) {
        const filteredContent = edges.filter((newsItem) => newsItem.node.displayText.toLowerCase().includes(value.toLowerCase()));
        //console.debug('handleFilterSelect - filteredContent', filteredContent);
        setFilterState({query: value, results: filteredContent});
      }
    } else {
      setFilterState({query: value, results: []});
    }
  }
  //console.debug('SearchNewsItem - filterState', filterState);

  const handleFilterClear = () => {
    //console.debug('SearchNewsItem - handleFilterClear');
    setFilterState({query: '', results: []});
  }

  return (
    <>
      <Segment basic className={'search'} style={{ padding: '0', marginTop: '25px', }}>
        <Container>
          <Input size={'big'}
            icon={filterState.query ? <Icon name='remove' link onClick={()=>handleFilterClear()} /> : <Icon name='search' />}
            name='filterQuery'
            placeholder='Search News...'
            value={filterState.query}
            onChange={(e)=>handleFilterSelect(e)}
          />
        </Container>
        {filterState.query !== null && filterState.results.length !== 0 ?
          <Segment placeholder className={'news-item-list'} style={{ marginTop: '25px', marginBottom: '25px', paddingTop: '50px', paddingBottom: '50px', border: 'none' }}>
            <Container>
              <h2 style={{color: '#230859', }}>Search Results</h2>
              <Divider />
              <Item.Group relaxed={'very'} divided style={{ minHeight: '100px', maxHeight: '600px', overflowY: 'auto', }}>
                {filterState.results.map((newsItem, i) => {
                  const { displayText, publicationDate, publishedUtc, authorInfo, authorName, path, thumbnailImage, } = newsItem.node;
                  const dateRender = publicationDate ? dayjs.utc(publicationDate).format('MMMM DD, YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM DD, YYYY') : null;
                  const datePath = publicationDate ? dayjs.utc(publicationDate).format('MMMM-DD-YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM-DD-YYYY') : null;
                  return (
                    <Item key={i} style={{ paddingRight: '15px', }}>
                      {thumbnailImage ?
                        <ItemImage displayText={displayText} thumbnailImage={thumbnailImage} />
                      :
                        null
                      }
                      {/* <ItemImage displayText={displayText} thumbnailImage={thumbnailImage} /> */}
                      <Item.Content>
                        <Item.Header as={Link} to={path ? ('/news-item/'+datePath+'/'+path) : null}>
                          <div className={'site-content-headline'}>
                            <Header as={'h3'} textAlign={'left'}>{displayText}</Header>
                          </div>
                        </Item.Header>
                        {authorInfo && authorInfo.html ?
                          <Item.Meta dangerouslySetInnerHTML={{__html: sanitizeHtmlContent(authorInfo.html)}}></Item.Meta>
                        : authorName ?
                          <Item.Meta dangerouslySetInnerHTML={{__html: sanitizeHtmlContent(authorName)}}></Item.Meta>
                        :
                          null
                        }
                        <Item.Extra>{dateRender}</Item.Extra>
                        {/* <Item.Description>
                          {displayText}
                        </Item.Description> */}
                      </Item.Content>
                      <Button className={'site-content-button outline'}
                        style={{ color: '#230859', borderColor: '#230859' }}
                        as={Link}
                        to={path ? ('/news-item/'+datePath+'/'+path) : null}
                        icon={'arrow right'}
                        labelPosition={'right'}
                        content={'Read More'}
                      />
                    </Item>
                  )
                })}
              </Item.Group>
            </Container>
          </Segment>
        :
          null
        }
      </Segment>
    </>
  )
}

export default SearchNewsItem
